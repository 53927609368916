import { motion, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import AnimatedQuote from './components/animatedQuote';
import SkillsHexagon from './components/dataGrid';
import TextSection from './components/textComponent';
import { OuterDiv } from '../styledComponents';

const AnimationContainer = styled.div`
  width: fit-content;
 
  @media screen and (max-width:1100px) {
      width: 100%;
      padding-top: 10px;
  } 
`

const InnerDiv = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
    max-width: 1400px;
   
    @media screen and (max-width:1100px) {
      flex-direction: column-reverse;
      justify-content: center;
      gap: 0px;
  } 
   
`

function HowWeDoIt({ scrollRef, animationControls }) {
  const animationItems = []   
  const ref = useRef(null)
  const inView = useInView(ref)

  useEffect(() => {
    if (typeof window !== "undefined") {
      let width = window.innerWidth;
      if (inView || width < 600) {
        animationControls.start("visible");
      }
    }

  }, [inView]);

  const InnerAnimation = {
    hidden: {
      opacity: 0, transition: {
        delay: 0,
        duration: 0.1
      }
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 5,
        duration: 1
      }
    }
  };

  return (

    <OuterDiv ref={scrollRef} minheight={'70vh'}>
      <AnimatedQuote motionRef={ref} controls={animationControls} />
      <InnerDiv
        initial={"visible"}
        ref={ref}
        animate={animationControls}
        variants={InnerAnimation}
        viewport={{ once: false }}
      >
        <TextSection ></TextSection>
        <AnimationContainer >
          <SkillsHexagon animationItems={animationItems} motionRef={ref} controls={animationControls} ></SkillsHexagon>
        </AnimationContainer>
      </InnerDiv>
    </OuterDiv>
  )
}

export default HowWeDoIt;