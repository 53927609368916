import React from 'react'
import styled from 'styled-components'
import TranslateText from '../../../utils/translateText'
import { DetailedTextLeft } from '../../styledComponents'

const StyledArticle = styled.span`
    width: 100%;
    max-width: 600px;
    margin-top: 75px;
    
    @media screen and (max-width:1100px) {
      width:100%;
      text-align: left;
      margin-top:0;
    } 
`

const BoxTitle = styled.h2`
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 6rem;
    transition: all 0.2s;
    letter-spacing: 1px;
    //text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    width: 100%;
    margin: 0;

    @media screen and (max-width:600px) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0;
    } 
`

const MobileBreak = styled.br`
    @media screen and (max-width:700px) {
        display: none;
    } 

`

function TextSection() {
    return (
        <StyledArticle>
            <BoxTitle>
                <TranslateText swedish={'Få din Data '} english={'Make your Data'} />
                <br />
                <TranslateText swedish={'Att jobba för Dig'} english={'Work for You'} />
            </BoxTitle>
            <DetailedTextLeft>
                <TranslateText
                    swedish={'Data kan ofta framstå som abstrakt. '} english={'Data can often be perceived as abstract.'} />
                <MobileBreak />
                <TranslateText
                    swedish={' Men med rätt verktyg, kan abstraktion bli insikter. '} english={'But with the right tools, abstractions can become insights.'} />
                <MobileBreak />
                <TranslateText
                    swedish={' Med AlgoFlows verktyg blir dessa insikter '} english={'With AlgoFLows tools these insights'} />
                <MobileBreak />
                <TranslateText
                    swedish={' direkt mätbara förbättringar.'} english={' are transformed to directly measureably improvements.'} />
            </DetailedTextLeft>
        </StyledArticle >
    )
}

export default TextSection