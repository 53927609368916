import { motion } from "framer-motion";
import React from 'react';
import styled from 'styled-components';

const InnerText = styled(motion.span)`

`;

const QuoteWrapper = styled.div` 
  display: flex;
  flex-direction:column;
  background-color: transparent;
  color: #f1f1f1;
  //padding: 2rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;

  @media (max-width: 600px)  {
    margin-bottom: 0;
    padding-left: 1rem;
  }
`;

const RowWrap = styled.div` 
  display: flex;
  flex-direction:row;
`;

const QuoteText = styled(motion.h2)`
  font-size: 3rem;
  font-weight: 400;
  font-style: italic;
  font-family: 'Familjen Grotesk', sans-serif;
  padding: 0;
  margin: 0;
  margin-bottom:0.5rem;
  display:inline-block;

  @media (max-width: 600px)  {
    font-size: 2rem;
  }

`;

const QuoteAuthor = styled(motion.p)`
  color:#f1f1f1;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: left;
  margin: 0;
  @media (max-width: 600px)  {
    font-size: 1rem;
  }
  
`;

const Credentials = styled(motion.span)`
  color:#888888;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: left;
  margin: 0;
  margin-top: 0.25rem;
  @media (max-width: 600px)  {
    font-size: 1rem;
  }
`;

function AnimatedQuote({ motionRef, controls, showAnimation }) {
    const author = "Taiichi Ohno"
    const credentials = "Originator of the Toyota Production System"
    const line1 = "Data is important, "
    const line2 = " but I prefer facts"

    const sentence = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.3,
                staggerChildren: 0.09,
            }
        }
    }

    const letter = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0
        }
    }

    const authorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration:0.7,
                delay: 3.8,
            }
        }
    };

    const credentialsVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration:0.7,
                delay: 3.8,
            }
        }
    };

    return (
        <QuoteWrapper>
            <RowWrap>
                <QuoteText
                    variants={sentence}
                    initial={showAnimation ?  "hidden" : "visible"}
                    ref={motionRef}
                    animate={controls}
                    viewport={{ once: false }}
                >
                    {(line1 + line2).split("").map((char, index) => {
                        return (
                            <InnerText key={char + "" + index} variants={letter}>
                                {char}
                            </InnerText>
                        )
                    })}
                </QuoteText>
            </RowWrap>
            <QuoteAuthor variants={authorVariants} initial={showAnimation ?  "hidden" : "visible"} ref={motionRef} animate={controls} viewport={{ once: false }}>
                {author}
            </QuoteAuthor>
            <Credentials variants={credentialsVariants} initial={showAnimation ?  "hidden" : "visible"} ref={motionRef} animate={controls} viewport={{ once: false }}>
                {credentials}
            </Credentials>
        </QuoteWrapper>
    );
}

export default AnimatedQuote