import Image from 'next/image'
import React, { useState } from 'react'
import styled from 'styled-components'
import { greytext } from '../../styledComponents'


const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:230px;
    height: 160px;

    @media (max-width: 1300px)  {
        width:180px;
    }

    @media (max-width: 1200px)  {
        width:230px;
    }

    @media (max-width: 650px)  {
        width: 32vw;
    }
`

const BoxTitle = styled.span`
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 4px;
    transition: all 0.2s;
    letter-spacing: 1px;
    text-transform: uppercase;
    //color:white;
    color:${greytext};
    @media (max-width: 550px)  {
        font-size: 12px;
        text-align: center;
        line-height: 14px;
    }
`

const ImageContainer = styled.div`
  position: relative;
  width: 90%;
  margin-bottom: 8px;
  height:40%;
  @media (max-width: 550px)  {
       width: 100%;
    }
`;

export default function GridObject({ item }) {

    return (
        <OuterContainer>
            <ImageContainer>
                <Image
                    src={item.image}
                    fill
                    style={{objectFit:"contain"}}
                    alt={item.alt}
                />
            </ImageContainer>
            <BoxTitle>{item.title}</BoxTitle>
        </OuterContainer>
    )
}
