export const firstColumn = [
    {
        image: '/logos/Ai.png',
        alt: 'html Logo',
        title: 'Ai'
    },
    {
        image: '/logos/neuralNet.png',
        alt: 'Neural networks logo',
        title: 'Neural networks'
    },

]

export const middleColumn = [
    {
        image: '/logos/Gaussian.svg',
        alt: 'Gauss equation',
        title: 'Gauss'
    },
    {
        image: '/logos/Markov.svg',
        alt: 'Markov equation',
        title: 'Markov'
    },
    {
        image: '/logos/Poission.svg',
        alt: 'Poisson equation',
        title: 'poisson'
    },
]

export const lastColumn = [
    {
        image: '/logos/iot.png',
        alt: 'IOT image',
        title: 'IOT'
    },
    {
        image: '/logos/bigData.png',
        alt: 'Big data image',
        title: 'Big Data'
    },
]
