import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { lastColumn, firstColumn, middleColumn } from './gridObjectData'
import GridObject from './gridObject'

const MotionBox = styled(motion.div)`
    width: fit-content;
    height: fit-content;
    margin: auto;

`

const RowContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    gap:10px;
    width: 100%;
   
`

const ColumnContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    gap:10px;
    
    @media (max-width: 480px)  {
        gap:7px;
    }
`

function SkillsHexagon({ }) {

    return (
        <RowContainer>
            <ColumnContainer>
                {firstColumn.map((item, index) =>
                    <MotionBox key={item.image} >
                        <GridObject item={item} key={item.image}></GridObject>
                    </MotionBox>
                )
                }
            </ColumnContainer>
            <ColumnContainer>
                {middleColumn.map((item, index) =>
                    <MotionBox key={item.image} >
                        <GridObject item={item} key={item.image}></GridObject>
                    </MotionBox>
                )}
            </ColumnContainer>
            <ColumnContainer>
                {lastColumn.map((item, index) =>
                    <MotionBox key={item.image} >
                        <GridObject item={item} key={item.image}></GridObject>
                    </MotionBox>
                )}
            </ColumnContainer>
        </RowContainer >
    )
}


/*
variants={animationItems[index + 2]}
                        initial="hidden"
                        ref={motionRef}
                        animate={controls}
                        viewport={{ once: false }}*/
export default SkillsHexagon